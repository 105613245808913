<template>
  <div>
    <article v-for="(sec, i) in localSections" :key="i" class="mb-2">
      <AppButton
        type="button"
        class="btn w-100 d-flex justify-content-between align-items-center py-0"
        :clickable="true"
        :variant="
          selectedActions &&
            selectedActions[sec.uuid] &&
            selectedActions[sec.uuid].includes(hasValue(sec, 'access'))
            ? 'primary'
            : 'secondary'
        "
        @click="toggleSection(sec.uuid)"
      >
        <div></div>
        {{ sec.name || sec.title }}
        <span class="p-1">
          <feather-icon
            icon="ChevronsDownIcon"
            size="16"
            class="text-white transition"
            :class="!unCollapsedSections.includes(sec.uuid) ? '' : 'rotate-180'"
          />
          <span class="sr-only">{{
            unCollapsedSections.includes(sec.uuid)
              ? $t("collapse_section") || "collapse section"
              : $t("expand_section") || "Display Section"
          }}</span>
        </span>
      </AppButton>
      <!-- @click="activateSectionHandler(sec)" -->
      <transition name="slide">
        <section v-if="unCollapsedSections.includes(sec.uuid)" class="border">
          <div class="p-2">
            <b-form-checkbox-group
              :id="`app-checkboxes-${sec.uuid}`"
              v-model="selectedActions[sec.uuid]"
              :name="`app-checkboxes-${sec.uuid}`"
              text-field="action.name"
              value-field="uuid"
              :options="sec.actions"
              :disabled="readonly"
            ></b-form-checkbox-group>

            <hr v-if="sec.actions && sec.actions.length" />

            <AppRadioBtns
              :id="`app-radios-${sec.uuid}`"
              :key="`app-radios-${sec.uuid}`"
              v-model="selectedRestrictions[sec.uuid]"
              name-field="text"
              value-field="value"
              :checked="selectedRestrictions[sec.uuid] || 'show_all'"
              :name="`app-radios-${sec.uuid}`"
              :label="$t('date_accessibility') || 'Data Accessibility:'"
              :options="restrictions"
              :disabled="readonly"
            />
          </div>
        </section>
      </transition>
    </article>
  </div>
</template>

<script>
import { BFormCheckboxGroup } from "bootstrap-vue";
import { AppButton, AppRadioBtns } from "@/components/form/index";

export default {
  components: {
    AppButton,
    AppRadioBtns,
    BFormCheckboxGroup,
  },
  props: {
    sections: {
      type: [Array, Object],
      required: true,
    },
    initialSelected: {
      type: [Array, Object],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPermissions: {},
      selectedActions: {},
      selected: "",
      selectedRestrictions: {},
      restrictions: [
        {
          text: this.$t("show_all_data") || "Show All Data",
          value: "show_all",
        },
        {
          text:
            this.$t("show_only_created_by_this_user") ||
            "Show Only created by this user",
          value: "show_only_user_data",
        },
      ],
      unCollapsedSections: [],
    };
  },
  computed: {
    localSections() {
      let sections = JSON.parse(JSON.stringify(this.sections));

      const userData = JSON.parse(localStorage.getItem("userData"));
      const userPermissions = userData?.permissions_actions || null;
      const isAdmin = userData.name === "admin";

      // if (!userPermissions && !isAdmin) this.$router.push("/login");

      // TODO => to be enhanced :: TimeComplixity: O(n)^2
      sections = sections.map((sec) => {
        // remove actions that current user doesn't have
        sec.actions = sec.actions
          .map(
            (action) =>
              ((userPermissions.includes(`${sec.name}-${action.action.name}`) ||
                isAdmin) &&
                action) ||
              undefined
          )
          .filter(Boolean);

        return sec;
      });

      if (isAdmin) sections = this.sections;
      return sections && [...sections];
    },
  },
  watch: {
    selectedActions: {
      handler() {
        this.generateFormData();
        this.generateRestrictions(this.selectedRestrictions);
      },
      deep: true,
      immediate: true,
    },
    selectedPermissions: {
      handler() {
        this.generateFormData();
        this.generateRestrictions(this.selectedRestrictions);
      },
      deep: true,
      immediate: true,
    },
    selectedRestrictions: {
      handler(val) {
        this.generateRestrictions(val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.initialSelected)
      this.setInitialSelection(
        JSON.parse(JSON.stringify(this.initialSelected))
      );
  },

  methods: {
    toggleSection(uuid) {
      this.unCollapsedSections = this.unCollapsedSections.includes(uuid)
        ? this.unCollapsedSections.filter((sec) => sec !== uuid)
        : [...this.unCollapsedSections, uuid];
    },
    activateSectionHandler(sec, event) {
      if (this.readonly) return;

      // set a new key in for the selected permissions
      this.$set(
        this.selectedPermissions,
        sec.uuid,
        !this.selectedPermissions[sec.uuid]
      );

      // this.toggleSection(sec.uuid);

      // set Action : (access) to true when premission is opened
      if (this.hasValue(sec, "access")) {
        this.$set(this.selectedActions, sec.uuid, [
          this.hasValue(sec, "access"),
        ]);
      } else {
        this.$set(this.selectedActions, sec.uuid, []);
      }

      // reset Selected actions if the permission unselected
      if (!this.selectedPermissions[sec.uuid]) {
        this.$set(this.selectedActions, sec.uuid, []);
      }
    },
    hasValue(obj, value) {
      // return uuid of the permission_action if the permission has an access action
      if (!obj || !obj.actions) return false;
      const actions = obj.actions.map((a) => ({
        ...a.action,
        primary_uuid: a.uuid,
      }));

      return actions.map((a) => a.name).includes(value)
        ? actions.filter((a) => a.name === value)[0]?.primary_uuid
        : false;
    },
    setInitialSelection(initialSelected) {
      // to be call only on created
      if (!initialSelected) return;

      if (initialSelected.restrictions) {
        initialSelected.restrictions.forEach((el) => {
          // this.selectedRestrictions[el] = "show_only_user_data";
          this.$set(this.selectedRestrictions, el, "show_only_user_data");
        });
        this.$emit(
          "updateRestrictions",
          (Object.keys(this.selectedRestrictions) || []).filter(Boolean)
        );
      }

      if (!initialSelected.permission_action) return;

      initialSelected.permission_action.map((ele) => {
        if (
          !ele.permission_actions.uuid ||
          !ele.permission_actions.permission.uuid
        )
          return;

        // set selected permissions
        this.$set(
          this.selectedPermissions,
          ele.permission_actions.permission.uuid,
          true
        );

        // set selected actions
        this.$set(
          this.selectedActions,
          ele.permission_actions.permission.uuid,
          [
            ...this.isObj(
              this.selectedActions[ele.permission_actions.permission.uuid]
            ),
            ele.permission_actions.uuid,
          ]
        );
      });
    },
    isObj(obj) {
      return obj && typeof obj === "object" ? obj : [];
    },
    generateFormData() {
      // merge all arrays in selectedActions to one array
      const formData = [].concat.apply(
        [],
        [...(Object.values(this.selectedActions) || [])]
      );
      this.$emit("formData", formData);
    },
    generateRestrictions(val) {
      const selectedPermissions = Object.keys(this.selectedPermissions)?.filter(
        (key) => this.selectedPermissions[key]
      );

      const selectedActions = Object.keys(this.selectedActions)?.filter(
        (key) => this.selectedActions[key] && this.selectedActions[key].length
      );

      // remove all except those in selectedPermissions
      // remove all keys with value show_all
      const restrictions = Object.keys(val)?.filter(
        (el) =>
          val[el] === "show_only_user_data" &&
          selectedPermissions?.includes(el) &&
          selectedActions?.includes(el)
      );

      this.$emit("updateRestrictions", (restrictions || []).filter(Boolean));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control-inline {
  margin-block: 0.5rem 1rem;
  text-transform: capitalize;
}
</style>
