<template>
  <AppForm
    :id="id"
    ref="rolesForm"
    :errors-bag="errorsBag"
    :is-loading="isLoading || isLoadingPermissions"
    :submit-label="role ? 'Edit' : 'Add'"
    :mode="formMode"
    :title="
      role
        ? $t('edit_form', { name: role.name }) || `Edit ${role.name}`
        : $t('add_new_role') || 'Add New Role'
    "
    @submit.prevent="handleSubmit"
    @hide="$emit('hide')"
  >
    <AppInput
      v-model="roleData['name']"
      type="text"
      required
      :label="$t('role') || 'Role'"
      rules="required"
      :disabled="mode === 'view'"
      @input="formMode = 'edit'"
    />
    <CollapseSections
      v-if="permissions && permissions.length"
      class="my-2"
      :readonly="mode === 'view'"
      :sections="permissions"
      :initial-selected="role || []"
      @formData="formDataHandler"
      @updateRestrictions="updateRestrictionsHandler"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm } from "@/components/form/index";
import CollapseSections from "./CollapseSections.vue";

export default {
  components: {
    AppInput,
    AppForm,
    CollapseSections,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    role: {
      type: Object,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: true,
      default: "add",
    },
  },
  data() {
    return {
      roleData: {
        name: "",
        permission_actions: [],
        restrictions: [],
      },
      formMode: this.mode,
    };
  },
  computed: {
    permissions() {
      return this.$store.state.permissions.data;
    },
    isLoadingPermissions() {
      return this.$store.state.permissions.isLoading.fetching;
    },
    isLoading() {
      return (
        this.$store.state.roles.isLoading.creating ||
        this.$store.state.roles.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.roles.errors;
    },
  },
  watch: {
    role: {
      handler(val) {
        if (!val) {
          this.roleData = {
            name: "",
            permission_actions: [],
            restrictions: [],
          };
          return;
        }

        this.roleData = {
          name: val.name,
          permission_actions: [...(val.permission_actions || [])],
          uuid: val.uuid || null,
          restrictions: [...(val.restrictions || [])],
        };

        // this.$watch("roleData", this.reCalcMode);
      },
      deep: true,
      immediate: true,
    },
    // change formMode to display the footer if user changes the actions or permissions
    roleData: {
      handler(val) {
        this.reCalcMode(val);
      },
      deep: true,
    },
    mode(val) {
      this.formMode = val;
    },
  },
  created() {
    this.fetchPermissions();
  },
  methods: {
    reCalcMode(val) {
      // TODO : allow the user to edit from the view mode
      // if (this.role && (!val || !val.name || !val.permission_actions)) {
      //   console.log(1);
      //   return;
      // }
      // if (
      //   val &&
      //   val.name === this.role.name &&
      //   val.permission_actions === [...val.permission_actions]
      // ) {
      //   console.log(2);
      //   return;
      // }
      // this.$nextTick(() => {
      //   console.log(3);
      //   this.formMode = "edit";
      // });
    },
    handleSubmit(e) {
      const actionName = this.role ? "updateData" : "createData";

      // remove restrictions array if empty
      const formData = JSON.parse(JSON.stringify(this.roleData));
      if (!formData.restrictions || !formData.restrictions.length)
        delete formData.restrictions;

      // console.log('formData["restrictions"]', formData["restrictions"]);
      this.$store.dispatch(`roles/${actionName}`, formData).then(() => {
        this.$root.$emit("bv::hide::modal", this.id);
        this.roleData = {};
      });
    },
    fetchPermissions() {
      this.$store.dispatch("permissions/fetchData");
    },
    hideForm() {
      this.$emit("hide");
      this.$root.$emit("bv::hide::modal", this.id);
      this.$refs.rolesForm.reset();
    },
    formDataHandler(data) {
      this.roleData.permission_actions = data;
      // this.formMode = "edit";
    },
    updateRestrictionsHandler(data) {
      this.roleData.restrictions = data;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
