var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.userCan('roles-add'))?_c('AppButton',{staticClass:"m-2 ml-auto",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openRolesForm()}}},[_vm._v(" "+_vm._s(_vm.$t("add_new_role") || "Add New Role")+" ")]):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":""}},[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.roles,"hover":"","striped":"","primary-key":"id","fields":_vm.fields,"show-empty":"","empty-text":_vm.$t('no_matching_records_found') || 'No matching records found',"fixed":false,"responsive":true,"selectable":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",class:("text-" + (data.item.name)),attrs:{"icon":data.item.name,"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"cell(permissions)",fn:function(data){return [(data.item.permission_action)?_c('div',[_vm._l((data.item.permission_action.slice(
                0,
                5
              )),function(permission,index){return _c('b-badge',{key:index,class:data.item.permission_action &&
                data.item.permission_action.length > 1
                  ? 'mb-end-1 mi-end-1'
                  : '',attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.openRolesForm(data.item, 'view')}}},[_vm._v(" "+_vm._s((" " + (data.item.permission_action[index].permission_actions && data.item.permission_action[index].permission_actions .permission ? data.item.permission_action[index].permission_actions .permission.name : "") + " ( " + (data.item.permission_action[index].permission_actions && data.item.permission_action[index].permission_actions .action ? data.item.permission_action[index].permission_actions .action.name : "") + " ) "))+" ")])}),_c('b-badge',{attrs:{"variant":"secondary","pill":"","title":"open All Permissions granted to this user"},on:{"click":function($event){return _vm.openRolesForm(data.item, 'view')}}},[(
                  data.item.permission_action &&
                  data.item.permission_action.length > 5
                )?_c('span',{staticClass:"text-capitalize"},[_vm._v(" +"+_vm._s(data.item.permission_action.length - 5)+" ")]):_vm._e()])],2):_vm._e()]}},{key:"cell(edit_delete)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column actions"},[(_vm.userCan('roles-edit'))?_c('AppButton',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openRolesForm(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t("edit") || "Edit")+" ")]):_vm._e(),(_vm.userCan('roles-delete'))?_c('AppButton',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.openDeletionWarning(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t("delete") || "Delete")+" ")]):_vm._e()],1)]}}])}),(_vm.roles && _vm.roles.length)?_c('div',{staticClass:"m-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("total_entries", { total: _vm.roles.length }))+" ")])]):_vm._e()],1)],1),(
      _vm.userCan('roles-add') || _vm.userCan('roles-edit') || _vm.userCan('roles-access')
    )?_c('RolesForm',{attrs:{"id":'roles-form',"role":_vm.activeRole,"mode":_vm.userCan('roles-add') || _vm.userCan('roles-edit') ? _vm.formMode : 'view'},on:{"hide":_vm.hideRolesForm}}):_vm._e(),_c('deleteWarning',{attrs:{"show":_vm.isDeletionWarnShown,"is-loading":_vm.isDeleting,"action-label":_vm.$t('delete') || 'Delete',"cancel-label":_vm.$t('cancel') || 'Cancel',"message":_vm.$t('are_you_sure') || 'Are You Sure?'},on:{"close":_vm.cancelDeletionHandler,"delete":_vm.deleteRole}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }