<template>
  <div>
    <b-card no-body class="mb-0">
      <AppButton
        v-if="userCan('roles-add')"
        class="m-2 ml-auto"
        variant="primary"
        @click="openRolesForm()"
      >
        {{ $t("add_new_role") || "Add New Role" }}
      </AppButton>

      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="roles"
          hover
          striped
          primary-key="id"
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
          :fixed="false"
          :responsive="true"
          :selectable="false"
        >
          <template #cell(name)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="data.item.name"
                size="18"
                class="mr-50"
                :class="`text-${data.item.name}`"
              />
              <span class="align-text-top text-capitalize">{{
                data.item.name
              }}</span>
            </div>
          </template>
          <template #cell(permissions)="data">
            <div v-if="data.item.permission_action">
              <b-badge
                v-for="(permission, index) in data.item.permission_action.slice(
                  0,
                  5
                )"
                :key="index"
                variant="secondary"
                pill
                :class="
                  data.item.permission_action &&
                  data.item.permission_action.length > 1
                    ? 'mb-end-1 mi-end-1'
                    : ''
                "
                @click="openRolesForm(data.item, 'view')"
              >
                {{
                  `
                  ${
                    data.item.permission_action[index].permission_actions &&
                    data.item.permission_action[index].permission_actions
                      .permission
                      ? data.item.permission_action[index].permission_actions
                          .permission.name
                      : ""
                  }
                    ( ${
                      data.item.permission_action[index].permission_actions &&
                      data.item.permission_action[index].permission_actions
                        .action
                        ? data.item.permission_action[index].permission_actions
                            .action.name
                        : ""
                    } )
                    `
                }}
              </b-badge>
              <b-badge
                variant="secondary"
                pill
                title="open All Permissions granted to this user"
                @click="openRolesForm(data.item, 'view')"
              >
                <span
                  v-if="
                    data.item.permission_action &&
                    data.item.permission_action.length > 5
                  "
                  class="text-capitalize"
                >
                  +{{ data.item.permission_action.length - 5 }}
                </span>
              </b-badge>
            </div>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('roles-edit')"
                variant="primary"
                class="mb-1"
                @click="openRolesForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                v-if="userCan('roles-delete')"
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div v-if="roles && roles.length" class="m-2">
          <span class="text-muted">
            {{ $t("total_entries", { total: roles.length }) }}
          </span>
        </div>
      </b-overlay>
    </b-card>

    <RolesForm
      v-if="
        userCan('roles-add') || userCan('roles-edit') || userCan('roles-access')
      "
      :id="'roles-form'"
      :role="activeRole"
      :mode="userCan('roles-add') || userCan('roles-edit') ? formMode : 'view'"
      @hide="hideRolesForm"
    />
    <deleteWarning
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deleteRole"
    />
  </div>
</template>

<script>
import { BCard, BButton, BTable, BBadge, BOverlay } from "bootstrap-vue";

import RolesForm from "./partials/RolesForm";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import { AppButton } from "@/components/form/index";
import userCan from "@/mixins/UserCan";

export default {
  name: "Roles",
  components: {
    RolesForm,
    BCard,
    BButton,
    BTable,
    BBadge,
    BOverlay,
    deleteWarning,
    AppButton,
  },
  data() {
    return {
      activeRole: null,
      fields: [
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "name",
          label: this.$t("name") || "Name",
        },
        {
          key: "permissions",
          label: this.$t("permissions") || "Permissions",
        },
      ],
      isDeletionWarnShown: false,
      permissionsInPopup: [],
      formMode: "edit",
    };
  },
  computed: {
    roles() {
      return this.$store.state.roles.data;
    },
    isLoading() {
      return this.$store.state.roles.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.roles.isLoading.deleting;
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    userCan,
    openRolesForm(item = null, mode = "edit") {
      if (item) this.activeRole = item;
      if (mode) this.formMode = mode;
      this.$root.$emit("bv::show::modal", "roles-form");
    },
    hideRolesForm() {
      this.activeRole = null;
      this.formMode = "edit";
    },
    fetchRoles() {
      this.$store.dispatch("roles/fetchData");
    },
    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activeRole = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activeRole = null;
    },
    deleteRole() {
      this.$store.dispatch("roles/deleteData", this.activeRole).then(() => {
        this.activeRole = null;
        this.isDeletionWarnShown = false;
      });
    },
    // openAllPermissions(permissions) {
    //   this.permissionsInPopup = permissions;
    //   this.$root.$emit("bv::show::modal", "all-permissions");
    // },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
